import React from "react";
import { Occurrences } from "../components/occurrences";
import { useCookies } from "react-cookie";
const indexStyle = require('../styles/index.module.scss');
export const CoronaWarning = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['coronaVirusWarning']);
    return ((typeof window !== 'undefined' && cookies.coronaVirusWarning !== 'seen') ?
        <React.Fragment>
            <div className={indexStyle.coronaWarningOverlay}>
                <div className={indexStyle.coronaFrame}>
                    
                    
                    
                    
                    <div className={indexStyle.coronaWarningContent}>

                        <h1>Liebes Warmer-Mai-Publikum, liebe Queer-Kultur-Interessierte!</h1>

                        <p>Wir freuen uns, euch und Ihnen auch 2023 mit dem «warmen mai» einen Monat lang Veranstaltungen von/mit/über
                            Themen und Menschen der lesbisch-schwul-bi-trans-inter-asexuell/aromantischen Communities zu präsentieren.
                            Ab Mitte März werden wir an dieser Stelle die Veranstaltungen bekanntgeben.
                        </p>

                        <p>In Vorfreude auf den Mai grüsst herzlich das Team vom «warmen mai»!</p>

                        <p>PS: Kulturelle Institutionen, queere Vereine, kunstschaffende, queere Personen
                            dürfen weiterhin ihre Veranstaltungen hier <a href="/register">anmelden</a>.
                        </p>

                        <button type={"button"} onClick={() => {
            let now = new Date().getTime();
            let expires = new Date(now + 6.048e+8);
            setCookie('coronaVirusWarning', 'seen', { path: "/", expires: expires });
        }}>
                            Schliessen
                        </button>
                    </div>
                </div>
            </div>
            <div className={indexStyle.coronaWarningOverlayBackground}/>
        </React.Fragment>
        : null);
};
export const Index = () => {
    return <React.Fragment>
        
        <Occurrences isArchive={false}/>
    </React.Fragment>;
};
export default Index;
